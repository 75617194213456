@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'juana';
    src: url('../assets/fonts/juana.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

html,
body {
    background-color: #AD9E8E;
    overflow-x: hidden;
}

img {
    cursor: pointer;
}

input:focus {
    outline-style: none;
}